<template>
  <div class="site-layout">
    <header class="flex-row navigation site-header" v-if="showHeaderFooter">
      <logo>
        <a :href="marketingDomain">
          <img src="@images/marketing_site/UserEvidence Login Logo.svg" alt="UserEvidence Logo" />
        </a>
      </logo>
      <nav class="nav">
        <ul class="flex-row menu-items">
          <li class="active">
            <a href="/user-research-library/" class="menu-link">Research Library</a>
          </li>
          <li>
            <!-- The id is used by the utmParams helper to add utm params to the link.  see utmParams.js -->
            <a role="button" id="back-to-homepage" class="menu-link">Back to Homepage</a>
          </li>
        </ul>
      </nav>
      <div class="btns">
        <a href="/users/sign_in" class="btn btn-small btn-signin">Log in </a>
        <a :href="marketingLink('book-demo')" class="btn btn-small btn-bookademo">Book demo</a>
      </div>
      <div class="btn-toggle btn-open" @click="toggleNavigation()">
        <svg
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          stroke="currentColor"
          stroke-width="0.7"
        >
          <path
            d="M3 7C3 6.44771 3.44772 6 4 6H24C24.5523 6 25 6.44771 25 7C25 7.55229 24.5523 8 24 8H4C3.44772 8 3 7.55229 3 7Z"
            fill="currentColor"
          ></path>
          <path
            d="M3 14C3 13.4477 3.44772 13 4 13H24C24.5523 13 25 13.4477 25 14C25 14.5523 24.5523 15 24 15H4C3.44772 15 3 14.5523 3 14Z"
            fill="currentColor"
          ></path>
          <path
            d="M4 20C3.44772 20 3 20.4477 3 21C3 21.5523 3.44772 22 4 22H24C24.5523 22 25 21.5523 25 21C25 20.4477 24.5523 20 24 20H4Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
      <div class="flex-row flex-column navigation navigation-md" :class="navMenuClass">
        <div class="flex-row top-header">
          <div class="logo">
            <img src="@images/marketing_site/UserEvidence Login Logo.svg" alt="UserEvidence Logo" />
          </div>
          <div class="btn-toggle btn-x" @click="toggleNavigation()">
            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
              <path
                fill="currentColor"
                d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
              ></path>
            </svg>
          </div>
        </div>
        <nav class="nav-md">
          <ul class="flex-row menu-items">
            <li>
              <a href="/user-research-library/" class="menu-link">Research Library</a>
            </li>
            <li>
              <!-- TODO: this is currently broken, clicking the button won't do anything. Seems to have been -->
              <!-- that way since roughly forever. Maybe something to do with utmParams.js not liking that this link shows -->
              <!-- up twice on the page, or that it is hidden when page loads. -->
              <a role="button" id="back-to-homepage" class="menu-link">Back to Homepage</a>
            </li>
          </ul>
        </nav>
        <div class="btns">
          <a href="/users/sign_in" class="btn btn-small btn-signin">Log in </a>
          <a :href="marketingLink('book-demo')" class="btn btn-small btn-bookademo">Book demo</a>
        </div>
      </div>
    </header>

    <slot />

    <footer
      class="footer site-footer"
      :class="{ 'sidebar-footer': sideBar }"
      v-if="showHeaderFooter"
    >
      <div class="inner-content flex-row copyrights-and-legal">
        <p class="mb-0">
          ©
          {{ currentYear }}
          UserEvidence. All rights reserved.
        </p>
        <ul class="terms-and-policy pl=0">
          <li>
            <a :href="marketingLink('terms')">Terms of Use</a>
          </li>
          <li>
            <a :href="marketingLink('privacy')">Privacy Policy</a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    showHeaderFooter: { type: Boolean, required: false, default: true },
    sideBar: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      showNavigation: false,
    }
  },
  computed: {
    navMenuClass() {
      return { 'show-navigation': this.showNavigation }
    },
    currentYear() {
      return new Date().getFullYear()
    },
    marketingDomain() {
      // TODO: this should probably be an env var but punting on that for now
      return 'https://www.userevidence.com'
    },
  },
  methods: {
    marketingLink(subPath) {
      return `${this.marketingDomain}/${subPath}`
    },
    toggleNavigation() {
      this.showNavigation = !this.showNavigation
    },
  },
}
</script>

<style lang="sass" scoped>
.site-layout
  min-height: 100vh
  display: grid
  grid-template-rows: auto 1fr auto
logo img
  max-width: 140px
footer
  /* Make sure we can position footer on top of microsite sidebar */
  position: relative
  height: 210px

.sidebar-footer
  width: 75%
  left: 25%

@media (max-width: 1200px)
  .sidebar-footer
    width: 100% !important
    left: 0 !important
</style>
